<template>
    <div class="overflow-index" style="width: 100%; padding: unset !important;">
        <slot name="header_actions"></slot>
        <table :id="'list_chart_' + extratable.id" style="min-width: 800px;">
            <thead v-if="data != ''" style="border-bottom: 1px solid #C7C7C7;">
            <tr class="header_char_table">
                <td v-for="(label, labelIndex) in header" :key="labelIndex" :style="labelIndex == 0 ? 'padding-left: 10px;' + label.style : label.style" class="T-subtitle">
                    <template v-if="label.chart && label.ruler">
                        <template v-if="type === 1 || type === 3 || (type === 2 && evaluator) || (type === 2 && leader)">
                            <div class="header_row_ruler">
                                <sup class="first_ruler">{{label.value_ruler !== undefined && label.value_ruler[0] !== undefined ? label.value_ruler[0] : '0'}}</sup>
                                <sup class="second_ruler">{{label.value_ruler !== undefined && label.value_ruler[1] ? label.value_ruler[1] : '20'}}</sup>
                                <sup class="third_ruler">{{label.value_ruler !== undefined && label.value_ruler[2] ? label.value_ruler[2] : '40'}}</sup>
                                <sup class="fourth_ruler">{{label.value_ruler !== undefined && label.value_ruler[3] ? label.value_ruler[3] : '60'}}</sup>
                                <sup class="fifth_ruler">{{label.value_ruler !== undefined && label.value_ruler[4] ? label.value_ruler[4] : '80'}}</sup>
                                <sup class="sixst_ruler">{{label.value_ruler !== undefined && label.value_ruler[5] ? label.value_ruler[5] : '100'}}</sup>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div :class="label.class" :title="label.full_name !== undefined && label.full_name !== '' ? $t(label.full_name) : $t(label.text)" style="cursor: default">
                            {{$t(label.text)}}
                        </div>
                    </template>
                </td>
            </tr>
            </thead>
            <div style="height: 15px"></div>
            <tbody>
            <tr v-for="(item, itemIndex) in data" :key="itemIndex" :hidden="item.parent_id !== undefined ? true : false" :item_id="item.id" :data_ref="'list_chart_'+extratable.id+'_'+item.id+'_operator'" :has_children="item.has_children" :style="(item.parent_id !== undefined ? 'background-color: #f6f6f6;' : '') + (header.find((i) => i.field !== 'chart') ? 'height: 40px;' : '')" :class="item.parent_id !== undefined ? extratable.id + '_contents_' + item.parent_id : ''" :ref="item.parent_id !== undefined ? extratable.id + '_contents_' + item.parent_id : ''">
                <td v-for="(label, labelIndex) in header" :key="labelIndex" :style="labelIndex == 0 ? 'padding-right: 5px;' + label.style : label.chart ? 'vertical-align: top;' : labelIndex != 0 && header.length != (labelIndex + 1) && !label.chart && !label.advice ? (header[labelIndex+1] !== undefined ? (header[labelIndex+1]['field'] == 'actions' ? 'vertical-align: top; ' + label.style : 'vertical-align: top; ' + label.style) : 'vertical-align: top; ' + label.style) : 'vertical-align: top; ' + label.style" class="ellipsis">
                    <template v-if="label.component">
                        <div style="float: left; padding-left: 10px;" :style="item.parent_id === undefined ? 'margin-top: 16px;' : 'margin-top: 7px;'">
                            <slot name="component" :itemProp="item"></slot>
                        </div>
                    </template>
                    <template v-else-if="label.chart">
                        <div style="display: flex" :style="item.parent_id !== undefined ? 'padding-top: 10px;' : 'padding-top: 8px;'">
                            <div class="bar-progress-chart" style="z-index: 1;position: relative;" :title="item[label.field].data[0].value" :style="'background-color: ' + item[label.field].colors[0] + '; width: ' + item[label.field].data[0].width + '%; height: ' + (item.parent_id !== undefined ? 10 : 32) + 'px;'">
                                <div class="first-progress bar-progress-chart" :title="item[label.field].data[1].value" :style="'background: linear-gradient(to right, ' + item[label.field].colors[1] + ', ' + item[label.field].colors[2] + '); width: ' + item[label.field].data[1].width + '%; height: ' + (item.parent_id !== undefined ? 10 : 32) + 'px;'"></div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="label.advice">
                        <div style="display: flex">
                            <div @click="item[label.field] !== undefined && item[label.field]['punctuation'] !== undefined && item[label.field]['punctuation'] !== '' ? $emit('columnPunctuation', item, label) : ''" style="display: flex; " :style="(item[label.field] !== undefined && item[label.field]['punctuation'] !== undefined && item[label.field]['punctuation'] !== '' ? 'cursor: pointer;' : '') + (item.has_children && item.level === 0 ? 'padding-top: 16px;' : 'padding-top: 7px;') + (type === 4 ? 'place-content: center; padding-left: 5px; padding-right: 5px; ' : '')" >
                                <label v-if="label.show_punctuation || type === 3" class="T-subtitle-second" style="min-width: 16px; cursor: pointer;">
                                    {{item[label.field] !== undefined && item[label.field]['punctuation'] !== undefined ? item[label.field]['punctuation'] : item[label.field]}}
                                </label>
                            </div>
                            <div @click="item[label.field] !== undefined && item[label.field]['hard_points'] === '' && item[label.field]['improvement_opportunities'] === '' && item[label.field]['good_practices'] === '' ? '' : $emit('columnAdvice', item, label)" style="display: flex;" :style="(item[label.field] !== undefined && item[label.field]['hard_points'] === '' && item[label.field]['improvement_opportunities'] === '' && item[label.field]['good_practices'] === '' ? '' : 'cursor: pointer;') + (item.has_children && item.level === 0 ? 'padding-top: 16px;' : 'padding-top: 7px;') + (type === 4 ? 'place-content: center; padding-left: 5px; padding-right: 5px;' : '')" >
                                <template v-if="item[label.field] !== undefined && item[label.field]['hard_points'] === ''"></template>
                                <template v-else>
                                    <div style="margin-top: -1px; cursor: pointer;">
                                        <span v-if="((type === 4 || type === 3) && item[label.field] !== undefined && item[label.field]['hard_points'] !== undefined) || (item['hard_points'] !== undefined && item['hard_points'] !== '')" :class="'content-star'" class="T-subtitle-second" style="cursor: pointer;"/>
                                    </div>
                                    <label v-if="((type === 4 || type === 3) && item[label.field] !== undefined && item[label.field]['hard_points'] !== undefined && item[label.field]['hard_points'] !== '') || (item['hard_points'] !== undefined && item['hard_points'] !== '')" class="T-subtitle-second" style="min-width: 16px; cursor: pointer;">{{(type === 4 || type === 3) && item[label.field] !== undefined && item[label.field]['hard_points'] !== undefined ? item[label.field]['hard_points'] : item['hard_points']}}</label>
                                </template>
                                <template v-if="item[label.field] !== undefined && item[label.field]['improvement_opportunities'] === ''"></template>
                                <template v-else>
                                    <div style="margin-top: -1px;">
                                        <label v-if="((type === 4 || type === 3) && item[label.field] !== undefined && item[label.field]['improvement_opportunities'] !== undefined && item[label.field]['improvement_opportunities'] !== '') || (item['improvement_opportunities'] !== undefined && item['improvement_opportunities'] !== '')" :class="'content-warning'" class="T-subtitle-second" style="cursor: pointer;"></label>
                                    </div>
                                    <label v-if="((type === 4 || type === 3) && item[label.field] !== undefined && item[label.field]['improvement_opportunities'] !== undefined && item[label.field]['improvement_opportunities'] !== '') || (item['improvement_opportunities'] !== undefined && item['improvement_opportunities'] !== '')" class="T-subtitle-second" style="min-width: 16px; cursor: pointer;">{{(type === 4 || type === 3) && item[label.field] !== undefined && item[label.field]['improvement_opportunities'] !== undefined ? item[label.field]['improvement_opportunities'] : item['improvement_opportunities']}}</label>
                                </template>
                                <template v-if="item[label.field] !== undefined && item[label.field]['good_practices'] === ''"></template>
                                <template v-else>
                                    <div style="margin-top: -1px;">
                                        <label v-if="((type === 4 || type === 3) && item[label.field] !== undefined && item[label.field]['good_practices'] !== undefined && item[label.field]['good_practices'] !== '') || (item['good_practices'] !== undefined && item['good_practices'] !== '')" :class="'content-good'" class="T-subtitle-second" style="cursor: pointer;"></label>
                                    </div>
                                    <label v-if="((type === 4 || type === 3) && item[label.field] !== undefined && item[label.field]['good_practices'] !== undefined && item[label.field]['good_practices'] !== '') || (item['good_practices'] !== undefined && item['good_practices'] !== '')" class="T-subtitle-second" style="min-width: 16px; cursor: pointer;">{{(type === 4 || type === 3) && item[label.field] !== undefined && item[label.field]['good_practices'] !== undefined ? item[label.field]['good_practices'] : item['good_practices']}}</label>
                                </template>
                                <slot v-if="label.slot" name="advice_actions" :itemProp="item"></slot>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="label.hard_points && item[label.field] !== undefined">
                        <div style="display: flex;" :style="(item.has_children && item.level === 0 ? 'padding-top: 16px;' : 'padding-top: 7px;')" >
                            <div style="margin-top: -1px; cursor: pointer;">
                                <span :class="'content-star'" class="T-subtitle-second"/>
                            </div>
                            <label class="T-subtitle-second" style="min-width: 16px; cursor: pointer;">{{item[label.field]}}</label>
                        </div>
                    </template>
                    <template v-else-if="label.improvement_opportunities && item[label.field] !== undefined">
                        <div style="display: flex;" :style="(item.has_children && item.level === 0 ? 'padding-top: 16px;' : 'padding-top: 7px;')" >
                            <div style="margin-top: -1px; cursor: pointer;">
                                <span :class="'content-warning'" class="T-subtitle-second"/>
                            </div>
                            <label class="T-subtitle-second" style="min-width: 16px; cursor: pointer;">{{item[label.field]}}</label>
                        </div>
                    </template>
                    <template v-else-if="label.good_practices && item[label.field] !== undefined">
                        <div style="display: flex;" :style="(item.has_children && item.level === 0 ? 'padding-top: 16px;' : 'padding-top: 7px;')" >
                            <div style="margin-top: -1px; cursor: pointer;">
                                <span :class="'content-good'" class="T-subtitle-second"/>
                            </div>
                            <label class="T-subtitle-second" style="min-width: 16px; cursor: pointer;">{{item[label.field]}}</label>
                        </div>
                    </template>
                    <template v-else-if="label.punctuation">
                        <div style="display: flex;" :style="(item.has_children && item.level === 0 ? 'padding-top: 16px;' : 'padding-top: 7px;') + (type === 4 ? 'place-content: center; padding-left: 5px; padding-right: 5px; cursor: pointer;' : '')" >
                            <label v-if="(type === 4 && item[label.field] !== undefined && item[label.field] !== '') || (item['punctuation'] !== undefined && item['punctuation'] !== '')" class="T-subtitle-second" :style="type === 4 ? 'cursor: pointer' : ''">{{type === 4 ? item[label.field] : item['punctuation']}}</label>
                        </div>
                    </template>
                    <template v-else-if="item.has_children && label.show_drag">
                        <template v-if="item.parent_id === undefined">
                            <div style="display: flex; padding-left: 7px" :style="item.state !== undefined && evaluator !== undefined && !evaluator ? 'width: 100%': ''">
                                <div class="T19" :key="'ACTION' + item.id + refreshAction" style="cursor: pointer; margin-right: 10px; max-width: 45px;" @click="toggleChildren(item.id)" :id="'list_chart_'+extratable.id+'_'+item.id+'_operator'" :ref="'list_chart_'+extratable.id+'_'+item.id+'_operator'">
                                    <img :toggle="toggle[item.id]" height="20" width="18" :src="!toggle[item.id] ? extratable.more : extratable.less" alt="" style="margin-top: 13px;">
                                </div>
                                <div style="display: contents;">
                                    <label class="T-subtitle-colored ellipsis" style="display: table-cell;margin-top: 13px;white-space: normal;padding-bottom: 8px;" :title="item[label.field]">{{item[label.field]}}</label>
                                    <template v-if="item.state !== undefined && evaluator !== undefined && !evaluator">
                                        <label class="T-small-text ellipsis" style="margin-top: 17px; margin-left: 20px; margin-right: 10px; display: table; color: rgb(183, 192, 197) !important;" :title="$t(states[item.state])">{{$t(states[item.state])}}</label>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div style="display: flex;" :style="(label.field === 'title' ? 'padding-left: ' + (item.level * 25) + 'px;' : '') + (item.state !== undefined && evaluator !== undefined && !evaluator ? 'width: 100%': '')">
                                <div style="display: contents;">
                                    <div style="min-height: 40px;">
                                        <label class="T-text" :title="item[label.field]" style="display: flex; padding-top: 5px;">
                                            <div class="gh-pointer" :style="'color: ' + extratable.pointer.colors[item.state]">
                                                ⬤
                                            </div>
                                            <span style="margin-top: 2px;display: table-cell;margin-top: 2px;white-space: normal;padding-bottom: 8px;">{{item[label.field]}}</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="T19" :key="'ACTION' + item.id + refreshAction" style="cursor: pointer; margin-right: 10px; max-width: 45px; padding-top: 5px;" @click="toggleChildren(item.id)" :id="'list_chart_'+extratable.id+'_'+item.id+'_operator'" :ref="'list_chart_'+extratable.id+'_'+item.id+'_operator'">
                                    <img :toggle="toggle[item.id]" height="20" width="18" :src="!toggle[item.id] ? extratable.more : extratable.less" alt="" style="margin-top: 1px;margin-left: 5px;">
                                </div>
                                <template v-if="item.state !== undefined && evaluator !== undefined && !evaluator">
                                    <label class="T-small-text ellipsis" style="margin-top: 7px; margin-left: 20px; margin-right: 10px; color: rgb(183, 192, 197) !important; display: table-cell;white-space: normal;padding-bottom: 8px;" :title="$t(states[item.state])">{{$t(states[item.state])}}</label>
                                </template>
                            </div>
                        </template>
                    </template>
                    <template v-else-if="label.field != 'actions'">
                        <div style="display: flex;">
                            <div style="display: contents;">
                                <div style="min-height: 40px;">
                                    <label class="T-text" :title="item[label.field]" :style="(label.click_punctuation && item[label.field] !== '' ? 'cursor: pointer;' : '') +(label.field === 'title' ? 'padding-left: ' + (item.level * 25) + 'px;' : '') + (label.field === 'title' ? 'display:flex;' : '') + (item.parent_id === undefined || label.field === 'title' ? 'padding-top: 5px' : 'margin-top:-4px;')" :class="[!label.show_drag ? 'content_row_table': '', item.parent_id === undefined ? 'T-subtitle': '']" @click="label.click_punctuation ? $emit('columnPunctuation', item, label) : ''">
                                        <div v-if="label.field === 'title'" class="gh-pointer" :style="'color: ' + extratable.pointer.colors[item.state]">
                                            ⬤
                                        </div>
                                        <template v-if="item[label.sup]">&nbsp;&nbsp;&nbsp;</template>
                                        <span style="margin-top: 2px;" :class="label.field !== 'title' ? 'T-subtitle-second' : 'T-text'" :style="(label.field !== 'title' ? 'margin-left: 20px;' : 'display: table-cell;margin-top: 2px;white-space: normal;padding-bottom: 8px;') + (label.style_be ? item.style : '')" >{{item[label.field]}}</span>
                                        <sup v-if="item[label.sup]" @click="$emit('sup', item)" :style="$listeners.sup ? 'cursor: pointer' : ''">
                                            {{'(' + item[label.sup] + ')'}}
                                        </sup>
                                        <slot v-if="label.slot" name="punctuation_actions" :itemProp="item"></slot>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div style="float: left; padding-left: 10px;" :style="item.parent_id === undefined ? 'margin-top: 16px;' : 'margin-top: 7px;'">
                            <slot name="actions" :itemProp="item"></slot>
                        </div>
                    </template>
                </td>
            </tr>
            </tbody>
            <tfoot v-if="data != ''">
            <tr v-for="(item, itemIndex) in footer" :key="itemIndex" class="footer_char_table T-subtitle">
                <td v-for="(label, labelIndex) in header" :key="labelIndex" :style="labelIndex == 0 ? 'padding-left: 7px' : ''">
                    <div :class="label.class">
                        {{item[label.field]}}
                    </div>
                </td>
            </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
export default {
    name: "GhChartTable",
    props:['extratable', 'header', 'data', 'footer', 'type', 'evaluator', 'leader', 'states'],
    data(){
        return {
            toggle: [],
            refreshAction: 0
        }
    },
    mounted() {
        this.data.forEach((i) => {
            this.toggle[i.id] = false
        });
    },
    methods: {
        toggleChildren(id) {
            this.toggle[id] = !this.toggle[id];
            var toggle = this.$refs[this.extratable.id + '_contents_' + id];
            for (var i = 0; i < toggle.length; i++) {
                if (toggle[i]['attributes'] !== undefined && toggle[i]['attributes']['has_children'] !== undefined && toggle[i]['attributes']['has_children']['value'] !== undefined && toggle[i]['attributes']['has_children']['value']) {
                    if (!toggle[i].hidden) {
                        if (toggle[i].children[0].children[0].children[1].children[0].attributes.toggle !== undefined && toggle[i].children[0].children[0].children[1].children[0].attributes.toggle.value) {
                            this.toggleChildren(toggle[i]['attributes']['item_id']['value']);
                        }
                        this.toggle[id] = false;
                    }
                }

                toggle[i].hidden = !toggle[i].hidden;
            }

            this.refreshAction++;
        }
    },
}
</script>